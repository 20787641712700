// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("toastr")
require("moment");
require("owl.carousel");
require("packs/side-nav-tabs");
import toastr from 'toastr'
import moment from 'moment';
import 'moment-timezone';
import 'owl.carousel';

window.toastr = toastr;
window.moment = moment;
window.Streame = {};
document.addEventListener("turbolinks:load", function () {
  Streame.getUserMembership = false;
  $('[data-toggle="tooltip"]').tooltip()
  setTimeout(function () {
      $('.loader').fadeOut();
    }, 1000
  );

  if (window.innerWidth < 768) {
    $('#open-chat span').text('chat');
    $('.side-nav, .chat-streame-submit, .chat-btn').removeClass('show');
  }

  $('.side-nav, .chat-streame-submit, .chat-btn').removeClass('show');
  $('#open-chat span').text('chat');
  $('.player-background').addClass('full');

  $('#open-chat').click(function () {
    if ($('.side-nav, .chat-streame-submit, .chat-btn').hasClass('show')) {
      $('.side-nav, .chat-streame-submit, .chat-btn').removeClass('show');
      $('#open-chat span').text('chat');
      $('.player-background').addClass('full');
    } else {
      $('#open-chat span').text('arrow_forward');
      $('.side-nav, .chat-streame-submit, .chat-btn').addClass('show');
      $('.player-background').removeClass('full');
    }
  });

  $('[data-next-btn]').each(function () {
    $(this).click(function (e) {
      e.preventDefault();
      $(this).closest('[data-carousel-container]')
      .find('[data-carousel]')
      .trigger('next.owl.carousel');
    });
  });
  $('[data-prev-btn]').each(function () {
    $(this).click(function (e) {
      e.preventDefault();
      $(this).closest('[data-carousel-container]')
      .find('[data-carousel]')
      .trigger('prev.owl.carousel');
    });
  });

  $('#hide-alert').click(function () {
    $('#container-info').fadeOut();
  });

  $('.btn-loading').each(function () {
    $(this).click(function () {
      $(this).html('<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');
      $(this).css('opacity', '.7')
    })
  });
});


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
