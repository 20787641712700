import consumer from "./consumer";

import subscription from "@rails/actioncable/src/subscription";

document.addEventListener('turbolinks:load', () => {
  const element = document.getElementById('event-id');

  if (element == null || element.length == 0) {
    if (consumer != null) {
      consumer.disconnect();
    }
    return;
  }

  const event = element.getAttribute('data-event-id');
  const uuid = element.getAttribute('data-uuid');

  if (!event) {
    if (!consumer != null) {
      consumer.disconnect();
    }
    return;
  }

  var talk = (message) => {
    if (alertElement === false) {
      $('#player-container').fadeIn();
    } else {
      $('#player-container').hide();

      if (player) {
        if (player.api('playing')) {
          player.api('pause');
        }

        if (player.api('isfullscreen')) {
          player.api('exitfullscreen');
        }
      }
    }
  }

  var cons = consumer.subscriptions.create({ channel: 'ChatChannel', event_uuid: event, uuid: uuid }, {
    initialized() {
    },

    connected() {
      console.log('connected to ChatChannel. event : ' + event + '.')
    },

    speak(message) {
      return this.perform('speak', {
        event_id: event,
        message: message,
        uuid: uuid
      })
    },

    rejected() {
      consumer.disconnect();
    },

    disconnected() {
      console.log('Disconnected from chat_channel');
    },

    received(data) {
      $(".chat-streame-submit.custom").find('#feedback').html('<span class="material-icons">check_circle </span>Gracias '+ data['first_name'] +', hemos enviado tu mensaje');
      setTimeout(function () {
        $(".chat-streame-submit.custom").find('#feedback').html('');
      }, 4000);

      var full_name = data['first_name'] + " " + data['last_name']
      if(full_name.length == 1){
        full_name = "S/N"
      }

      $("#messages").append("<div class='message'>" + "<p><b>" + full_name + "</b>" + " " + data['content'] + "</p>" + "</div>");
      var objDiv = document.getElementById("messages");
      objDiv.scrollTop = objDiv.scrollHeight;

      while($(".message").length > 100){
        $(".message")[0].remove();
      }

      $("#messages").find('#message-alert').hide();
    }
  });

  if (document.getElementById("send_message")) {
    document.getElementById("send_message").addEventListener("click", function (event) {
      event.preventDefault();
      cons.speak($("#input").val())
      $("#input").val('')
    });
  }

});
