import consumer from './consumer';

document.addEventListener('turbolinks:load', () => {
  let player;
  const element = document.getElementById('event-id');

  if (element == null || element.length == 0) {
    if (consumer != null) {
      consumer.disconnect();
    }
    return;
  }

  const event = element.getAttribute('data-event-id');
  const uuid = element.getAttribute('data-uuid');
  const code = element.getAttribute('data-code');
  const watermark = element.getAttribute('data-watermark');

  if (!event) {
    if (!consumer != null) {
      consumer.disconnect();
    }
    return;
  }

  var isWowza = (value) => value != undefined && value.includes('.m3u8');
  var isIframe = (value) => value != undefined && value.includes('iframe');
  var isVimeo = (value) => isIframe(value) && value.includes('vimeo');
  var isYoutube = (value) => isIframe(value) && value.includes('youtu');

  var isMobileSafari = () => {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    return iOS && webkit && !ua.match(/CriOS/i);
  };

  var reloadPage = () => {
    document.location.href = document.location.href;
  }

  var playerAlert = (alertElement) => {
    if (alertElement === false) {
      $('#player-container').fadeIn();
    } else {
      $('#player-container').hide();

      if (player) {
        if (player.api('playing')) {
          player.api('pause');
        }

        if (player.api('isfullscreen')) {
          player.api('exitfullscreen');
        }
      } else {
        $('iframe[src*=youtu],iframe[src*=vimeo]').remove();
      }
    }

    var alert = $('#player-alert');
    alert.find('#connected_somewhere_else').toggle('#connected_somewhere_else' == alertElement);
    alert.find('#reject_subscription').toggle('#reject_subscription' == alertElement);
    alert.find('#level_load_error').toggle('#level_load_error' == alertElement);
    alert.find('#manifest_load_error').toggle('#manifest_load_error' == alertElement);
    alert.find('#load_error').toggle('#load_error' == alertElement);
    alert.find('#incompatible_browser').toggle('#incompatible_browser' == alertElement);
    if (alertElement !== false) {
      alert.fadeIn();
    } else {
      alert.hide();
    }
  };

  var handlePlayerEvents = () => {
    var playerElement = document.getElementById('player');
    playerElement.addEventListener('init', (e) => {
      console.debug(`Player event: ${e.type}`);
    });
    playerElement.addEventListener('start', (e) => {
      console.debug(`Player event: ${e.type}`);
    });
    playerElement.addEventListener('play', (e) => {
      console.debug(`Player event: ${e.type}`);
      $('.container-info').hide()
    });
    playerElement.addEventListener('pause', (e) => {
      console.debug(`Player event: ${e.type}`);
    });
    playerElement.addEventListener('loaderror', (e) => {
      console.debug(`Player event: ${e.type}`);

      const url = window.localStorage.getItem('url');
      if (!Hls.isSupported() && isMobileSafari() && url.includes('.m3u8')) {
        playerAlert('#incompatible_browser');
        return;
      }

      if (e.info === 'manifestLoadError') {
        playerAlert('#manifest_load_error');
      } else if (e.info === 'levelLoadError') {
        playerAlert('#level_load_error');
      } else if (e.info === 'not found') {
        playerAlert('#load_error');
      }

      setTimeout(() => {
        reloadPage();
      }, Math.floor((Math.random() * 10000) + 20000));
    });
  };

  var handlePlayerMessage = (data) => {
    window.localStorage.clear();

    if (isWowza(data.content) && !!$('#player').length) {
      var file = data.content;

      window.localStorage.setItem('content', data.content);
      if (data.content_hdnts != undefined) {
        if (Hls.isSupported()) {
          window.localStorage.setItem('hdnts', data.content_hdnts);
        } else {
          file += '?' + data.content_hdnts;
        }
      }

      if (player === undefined) {
        player = new Playerjs({
          id: 'player',
          file: file,
          autoplay: 1,
          wid: (watermark === 'true' ? code : undefined),
          hlsconfig: {
            loader: (config) => {
              var loader = new Hls.DefaultConfig.loader(config);

              return {
                abort: () => loader.abort(),
                destroy: () => loader.destroy(),
                load: (context, config, callbacks) => {
                  var hdnts = window.localStorage.getItem('hdnts');
                  if (hdnts && hdnts.length > 0) {
                    context.url = context.url + '?' + hdnts;
                  }

                  loader.load(context, config, callbacks);
                }
              }
            }
          }
        });

        playerAlert(false);

        window.onmessage = (e) => {
          if (e.data == 'play_streame_player') {
            player.api('play');
            player.api('fullscreen');
          } else if (e.data == 'pause_streame_player') {
            player.api('pause');
          } else if (e.data == 'refresh_streame_player') {
            reloadPage();
          }
        };

        handlePlayerEvents();
      }

      $('#player').removeClass('videoWrapper');
    } else if (isYoutube(data.content) || isVimeo(data.content) || isIframe(data.content)) {
      window.localStorage.setItem('content', data.content);
      $('#player').addClass('videoWrapper').html(data.content);
    }
  };

  var handleRefreshMessage = (data) => {
    const content = window.localStorage.getItem('content');

    if (isWowza(data.content)) {
      let file = data.content;

      if (data.content_hdnts != undefined) {
        if (Hls.isSupported()) {
          window.localStorage.setItem('hdnts', data.content_hdnts);
        } else {
          file += '?' + data.content_hdnts;
        }
      }

      if (content !== data.content) {
        window.localStorage.setItem('content', data.content);
        player.api('play', file);
      } else if (!Hls.isSupported()) {
        $.ajax({
          url: file,
          xhrFields: {
            withCredentials: true
          }
        });
      }

      $('#player').removeClass('videoWrapper');
    } else if (isYoutube(data.content) || isVimeo(data.content)) {
      if (content !== data.content) {
        window.localStorage.setItem('content', data.content);
        $('#player').addClass('videoWrapper').html(data.content);
      }
    }
  };

  var handleSomewhereElseMessage = (data) => {
    playerAlert('#connected_somewhere_else');
    consumer.disconnect();
  };

  consumer.subscriptions.create({ channel: 'EventChannel', event_uuid: event, uuid: uuid, code: code }, {
    initialized() {
      window.onmessage = (e) => {
        if (e.data == 'refresh_streame_player') {
          reloadPage();
        }
      };
    },

    connected() {
      this.speak();
    },

    speak() {
      return this.perform('speak', {});
    },

    rejected() {
      playerAlert('#reject_subscription');
      consumer.disconnect();
    },

    disconnected() {
      console.debug('Disconnected from event channel');
    },

    received(data) {
      if (data.type == 'player') {
        handlePlayerMessage(data);
      } else if (data.type == 'connected_somewhere_else') {
        handleSomewhereElseMessage(data);
      } else if (data.type == 'refresh') {
        handleRefreshMessage(data);
      }
    }
  });
});
