document.addEventListener("turbolinks:load", function () {
  if ($('.tab-control > .tab-btn').first()) {
    const $activeTab = $('.tab-control > .tab-btn').first();
    $activeTab.addClass('active');
    $('[data-tab-content="' + $activeTab.attr('id') + '"]').fadeIn();

    $activeTab.addClass('active');
    $('#side_nav_tab .tab-btn').click(function () {
      $('.tab-control > .tab-btn').removeClass('active');
      $(this).addClass('active');
      $('[data-tab-content]').hide();
      $('[data-tab-content="' + $(this).attr('id') + '"]').show();
    });
  }
});